<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table  table-striped bg-white">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, i) in users" :key="`user-${i}`">
              <td>{{ i + 1 }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.phone_number }}</td>
              <td>
                <router-link
                  :to="{ name: 'users.show', params: { id: user.id } }"
                  class="btn btn-sm btn-info" style="color:white !important" 
                  ><i class="lni-eye mr-2"></i>Details</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      users: [],
    };
  },

  methods: {
    fetch() {
      this.$loader.start();
      this.$axios.get("/api/v1/dashboard/users").then((response) => {
        this.users = response.data.users;
        setTimeout(() => {
          this.$loader.stop();
          $("table").DataTable();
        }, 1000);
      });
    },
  },
};
</script>
